import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { CloudinaryProvider } from "react-tiny-cloudinary";
import { DebugProvider } from "helpers/debug";

function MyApp({ Component, pageProps, err }: AppProps & { err: any }) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <DebugProvider>
      <CloudinaryProvider
        cloudName={process.env.NEXT_PUBLIC_CLOUDINARY_NAME || "emtek"}
        defaultTransformation={{
          defaultImage: "product-placeholder.jpg",
          dpr: "auto",
          fetchFormat: "auto",
          quality: "auto",
        }}
      >
        <Component {...pageProps} err={err} />
      </CloudinaryProvider>
    </DebugProvider>
  );
}

export default MyApp;
